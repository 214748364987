















































import { MutationPayload } from 'vuex';
import store from '../store';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  data() {
    return {
      name: '',
      password: '',
      loading: false,
      error: false,
      message: '',
      inputName: {
        id: 'user-name',
        name: 'name',
        type: 'text',
        placeholder: 'Name',
        value: '',
      },
      inputPassword: {
        id: 'user-password',
        name: 'password',
        type: 'password',
        placeholder: 'Password',
        value: '',
      },
      userIsSetup: false,
    };
  },
  methods: {
    setupUser() {
      const { name, password } = this;
      if (this.$route.query.id && this.$route.query.ct) {
        const id = parseInt(this.$route.query.id as string, 10);
        const ct = this.$route.query.ct as string;
        this.loading = true;
        store.dispatch('editUser', {
          id,
          name,
          password,
          ct,
        });
      }

      store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'editUser') {
          this.userIsSetup = true;
          this.loading = false;
          setTimeout(() => {
            window.location.href = `${process.env.VUE_APP_REDIRECT_URL}/sign-in`;
          }, 3000);
        }
        if (mutation.type === 'setUsersError') {
          this.error = true;
          this.loading = false;
          this.message = mutation.payload;
        }
      });
    },
  },
});
